.App {
  text-align: left;
}

.App-logo {
  height: 3vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  background-color: rgb(8, 0, 16);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: rgb(138, 87, 28);
}

.App-list {
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: rgb(24, 107, 28);
}

.App-link {
  color: #61dafb;
}

.shortline {
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
  color: rgb(204, 180, 169);
}

.up {
  color: green !important;
}

.down {
  color: orangered !important;
}


.farmbuttonspacing {
  margin-left: .5em;
  margin-right: .5em;
  margin-bottom: .5em;
  width: 6em;
}

.dashstat  {
  font-size: .9em;
}

.uphide {
  visibility: visible;
}

.downhide {
  visibility: hidden;
}

.reginfo {
  font-size: 1em;
}

.smallinfo {
  font-size: .5em;
}

.biginfo {
  font-size: 1em;
}

.runningman {
  font-size: 1.4em;
}

.toggle {
  font-size: 2em;
}

.cursortoggle {
  cursor: pointer;
}

.cursortoggle:hover {
  cursor: pointer;
}

.togglecheck {
  display: none;
}

.padit {
  padding: .4em;
}

.rem-big {
  font-size: 1.2em;
}

.detailhdr {
  background-color: black;
  color: rgb(178, 172, 214);
}

.detailtxt {
  color: rgb(178, 172, 214);
}

.shorttext {
  background-color: rgb(0, 0, 0);
  font-size: .8em;
  color: rgb(204, 180, 169);
  vertical-align: middle;
  line-height: 24px;
  padding-top: 5px;
}

.botshdr {
  background-color: rgb(52, 87, 66);
  color: rgb(178, 172, 214);
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 20px;
  width: 90%;
  font-size: 1.2em;
  }

.calmain {
  color: rgb(138, 87, 28) !important;
  background-color: rgb(0, 8, 82) !important;
}

.react-calendar__navigation__arrow {
  color: rgb(138, 87, 28) !important;
  background-color: rgb(0, 5, 46) !important;
}
.react-calendar__navigation__arrow:hover {
  background-color:black !important;
}



button {
  color: rgb(138, 87, 28) !important;
  background-color: rgb(0, 8, 82) !important;
}

.fresh {
  color: rgb(162, 228, 156) !important;
}

.calon {
  color: rgb(162, 228, 156) !important;
    background-color: rgb(37, 73, 55) !important;
}

.caloff {
  color: rgb(255, 51, 0) !important;
  background-color: rgb(13, 11, 39) !important;
}

.caldone {
  color: rgb(124, 115, 84) !important;
  background-color: rgb(0, 8, 82) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}